import { render, staticRenderFns } from "./huiyuanXiaofeikaSee.vue?vue&type=template&id=65821724&scoped=true&"
import script from "./huiyuanXiaofeikaSee.vue?vue&type=script&lang=js&"
export * from "./huiyuanXiaofeikaSee.vue?vue&type=script&lang=js&"
import style0 from "./huiyuanXiaofeikaSee.vue?vue&type=style&index=0&id=65821724&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65821724",
  null
  
)

export default component.exports