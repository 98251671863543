<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">编辑消费卡</div>
				<img class="HSDialogCloseImg" src="../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="80px">
				<el-row >
					<el-col :span="24">
						<el-form-item label="绑定服务" required>
							{{serviceName}}
							<el-button type="text" @click="dialogProduct_state=true">修改</el-button>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row >
					<el-col :span="24">
						<el-form-item label="剩余次数" required>
							<el-input v-model="times" @input="inputTimes"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row >
					<el-col :span="24">
						<el-form-item label="有效期至" required>
							<el-date-picker
								style="width:100%"
								v-model="date"
								type="date"
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row >
					<el-col :span="24">
						<el-form-item label="备注">
							<el-input type="textarea" :rows="3" v-model="remark"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
		<div v-if="dialogProduct_state" style="position: absolute;">
			<dialogProduct :tree="[{id:'613818278b215cafbb25d119'}]" @submit="submit_dialogProduct"></dialogProduct>
		</div>
	</div>
</template>
<script>
	import dialogProduct from "../../../components/dialogProduct.vue"
	export default {
		components:{dialogProduct},
		data() {
			return {
				times:'',
				date:"",
				serviceId:'',
				serviceName:'',
				remark:'',
				// 弹窗
				dialogProduct_state:false,
			}
		},
		computed:{
			
		},
		props:{
			curItem:{
				default:()=>{
					return {}
				}
			},
		},
		mounted(){
			this.times = this.curItem.surplusCount
			this.date = new Date(this.curItem.endDate*1000)
			this.serviceId = this.curItem.lifeServiceId
			this.serviceName = this.curItem.lifeServiceName
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				let params = {
					cardNumber:this.curItem.cardNumber,
					count:Number(this.times), 
					serviceId:this.serviceId,
					endTime:this.$public.FTimeStr10(this.date),
					remark:this.remark,
				}
				let loading = this.$loading()
				this.$http.post("/customer/MemberCards/ChangeCard",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.$emit("submit",{type:"success",data:""})
					}
				})
			},
			inputTimes(){
				this.times = this.$public.replaceToDouble(this.times)
			},
			// 弹窗
			submit_dialogProduct(obj){
				if(obj.type==='success'){
					if(obj.data.length===0){
						this.$message({
							message: '请选择服务项',
							type: 'error'
						});
					}else if(obj.data.length===1){
						this.serviceId = obj.data[0].newData.productId
						this.serviceName = obj.data[0].newData.productName
						this.dialogProduct_state = false;
					}else{
						this.$message({
							message: '只能添加一个服务项',
							type: 'error'
						});
					}
				}else{
					this.dialogProduct_state = false;
				}
			},
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
