<template>
	<div class="page">
		<div class="title">
			<div class="titleL">会员消费卡</div>
			<div class="titleR">
				<el-button type="infor" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<!-- 搜索栏 -->
		<div class="tabView">
			<div class="tabViewB">
				<div class="search">
					<div class="searchTitle">是否有效</div>
					<el-select v-model="isValid" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="item in [{value:0,label:'全部'},{value:1,label:'有效'},{value:2,label:'已失效'},]" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="tabViewBR">
					<!-- <el-button type="primary">修改积分</el-button> -->
					<!-- <el-button>导出</el-button>
					<el-button type="primary" >新建商品</el-button> -->
				</div>
			</div>
		</div>
		<!-- 表格 -->
		<div class="tableBox">
			<el-table :data="tableData" height="100%" >
				<el-table-column label="名称" min-width="100">
					<template slot-scope="scope">
						{{scope.row.cardName}}
					</template>
				</el-table-column>
				<el-table-column label="适用服务" min-width="100">
					<template slot-scope="scope">
						{{scope.row.lifeServiceName}}
					</template>
				</el-table-column>
				<el-table-column label="适用体重" min-width="90">
					<template slot-scope="scope">
						{{scope.row.weightMin}}~{{scope.row.weightMax}}kg
					</template>
				</el-table-column>
				<el-table-column label="分类" min-width="90">
					<template slot-scope="scope">
						{{scope.row.cardCategory.name}}
					</template>
				</el-table-column>
				<el-table-column label="有效期至" min-width="100">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.endDate,"YYYY-MM-DD")}}
					</template>
				</el-table-column>
				<el-table-column label="总次数" min-width="70">
					<template slot-scope="scope">
						{{scope.row.totalCount}}
					</template>
				</el-table-column>
				<el-table-column label="剩余次数" min-width="80">
					<template slot-scope="scope">
						{{scope.row.surplusCount}}
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="80">
					<template slot-scope="scope">
						<el-button type="text" @click="see(scope.row)">查看</el-button>
						<el-button type="text" @click="edit(scope.row)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		<div v-if="dialogXizaokaDetail_state">
			<dialogXizaokaDetail :curItem="curRow" @submit="submit_dialogXizaokaDetail"></dialogXizaokaDetail>
		</div>
		<div v-if="dialogEditXiaofeika_state">
			<dialogEditXiaofeika :curItem="curRow" @submit="submit_dialogEditXiaofeika"></dialogEditXiaofeika>
		</div>
	</div>
</template>
<script>
	import dialogXizaokaDetail from "../../../components/dialogXizaokaDetail.vue"
	import dialogEditXiaofeika from "./dialogEditXiaofeika.vue"
	import page from "../../../components/page.vue"
	export default {
		components: {dialogXizaokaDetail,dialogEditXiaofeika,page},
		data() {
			return {
				// search
				isValid:0,
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				// 详情
				dialogXizaokaDetail_state:false,
				curRow:{},
				// 编辑
				dialogEditXiaofeika_state:false,
			}
		},
		computed:{
			
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			// 搜索
			toSearch(){
				this.page.pageIndex=1;
				this.getTableData()
			},
			// 获取优惠券信息
			changePage(num){
				this.page.pageIndex = num;
				this.getTableData()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			getTableData(){
				let params = {
					memberId:this.$route.query.id,
					isValid:this.isValid,  // [{value:0,label:'全部'},{value:1,label:'有效'},{value:2,label:'已失效'},]
					pageIndex:this.page.pageIndex,
					pageSize:this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.get("/customer/MemberCards/CardList",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// 详情
			see(row){
				this.curRow = row
				this.dialogXizaokaDetail_state = true;
			},
			submit_dialogXizaokaDetail(){
				this.dialogXizaokaDetail_state = false;
			},
			// 编辑
			edit(row){
				this.curRow = row
				this.dialogEditXiaofeika_state = true;
			},
			submit_dialogEditXiaofeika(obj){
				this.dialogEditXiaofeika_state = false;
				if(obj.type==='success'){
					this.getTableData();
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		width:100%;height: 100%;border-radius: 20px;overflow: hidden;color: $fontColor;background-color: #fff;
		.title{
			display: flex;justify-content: space-between;border-bottom: 1px solid #f2f2f2;height: 68px;box-sizing: border-box;padding:14px 20px;
			.titleL{
				font-size: 16px;color:$fontColor;line-height: 40px;
			}
			.titleR{
				
			}
		}
		// 搜索信息
		.tabView {
			height: 80px;
			border-radius: 20px;
			background: #fff;
			display: flex;
			flex-direction: column;
		
			.tabViewT {
				height: 80px;
				border-bottom: solid 1px #F2F2F2;
				display: flex;
				align-items: center;
		
				.tabItem {
					width: 116px;
					line-height: 40px;
					text-align: center;
					font-size: 16px;
					border-radius: 40px;
					margin-left: 20px;
					cursor: pointer;
					background: #FFF0E0;
				}
		
				.active {
					background: #F77E04;
					color: #fff;
				}
			}
		
			.tabViewB {
				
				flex: 1;
				display: flex;
				align-items: center;
				padding: 0 20px;
		
				.search {
					display: flex;
					align-items: center;
					margin-right: 32px;
		
					.searchTitle {
						font-size: 14px;
						padding-right: 4px;
						color: $fontColor;
					}
				}
		
				.tabViewBR {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
			}
		}
		.tableBox{
			height: calc(100% - 200px);
		}
	}
</style>
